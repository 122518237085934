<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Game statistics</div>
                   </div>
                   <div class="record__gamess">
                      <div :class="['today', { active: currentTab === 'tab1' }]" @click="setTab('tab1')">
                         Today
                      </div>
                      <div :class="['yesterday', { active: currentTab === 'tab2' }]" @click="setTab('tab2')">
                         Yesterday
                      </div>
                      <div :class="['week', { active: currentTab === 'tab3' }]" @click="setTab('tab3')">
                         This Week
                      </div>
                      <div :class="['month', { active: currentTab === 'tab4' }]" @click="setTab('tab4')">
                         This Month
                      </div>
                   </div>
                   <div v-if="currentTab === 'tab1'">
                      <div class="amount__box" v-if="userdetails.total_bet!=null">
                         <h3>₹{{ Number(userdetails.total_bet).toFixed(2) }}</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="amount__box" v-else>
                         <h3>₹0.00</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="result__box" v-if="userdetails.total_num>0">
                         <div data-v-0fabbe57="" class="gamestats-container-list-wrapper">
                            <div data-v-0fabbe57="" class="gamestats-container-items">
                               <div data-v-0fabbe57="" class="gamestats-container-item">
                                  <h1 data-v-0fabbe57=""><img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/loterry-13b4d059.png" src="../assets/png/loterry-13b4d059.png"><span data-v-0fabbe57="">lottery</span></h1>
                                  <div data-v-0fabbe57="" class="gamestats-container-item-content">
                                     <img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/gameStatsSteps-fde7a66f.png" src="../assets/png/gameStatsSteps-fde7a66f.png">
                                     <div data-v-0fabbe57="" class="gamestats-container-item-content-list">
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Total bet</h4>
                                           <h5 data-v-0fabbe57="">₹{{ Number(userdetails.total_bet).toFixed(2) }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Number of bets</h4>
                                           <h5 data-v-0fabbe57="">{{ userdetails.total_num }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Winning amount</h4>
                                           <span data-v-0fabbe57="">₹{{ Number(userdetails.total_win).toFixed(2) }}</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                      <div v-else>
                        <svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity: 0.2; margin-top: 40px;"><use xlink:href="#icon-empty"></use></svg>
                        <p class="text-center">No Data</p>
                      </div>
                   </div>
                   <div v-if="currentTab === 'tab2'">
                      <div class="amount__box" v-if="userdetails.total_bet1!=null">
                         <h3>₹{{ Number(userdetails.total_bet1).toFixed(2) }}</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="amount__box" v-else>
                         <h3>₹0.00</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="result__box" v-if="userdetails.total_num1>0">
                         <div data-v-0fabbe57="" class="gamestats-container-list-wrapper">
                            <div data-v-0fabbe57="" class="gamestats-container-items">
                               <div data-v-0fabbe57="" class="gamestats-container-item">
                                  <h1 data-v-0fabbe57=""><img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/loterry-13b4d059.png" src="../assets/png/loterry-13b4d059.png"><span data-v-0fabbe57="">lottery</span></h1>
                                  <div data-v-0fabbe57="" class="gamestats-container-item-content">
                                     <img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/gameStatsSteps-fde7a66f.png" src="../assets/png/gameStatsSteps-fde7a66f.png">
                                     <div data-v-0fabbe57="" class="gamestats-container-item-content-list">
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Total bet</h4>
                                           <h5 data-v-0fabbe57="">₹{{ Number(userdetails.total_bet1).toFixed(2) }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Number of bets</h4>
                                           <h5 data-v-0fabbe57="">{{ userdetails.total_num1 }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Winning amount</h4>
                                           <span data-v-0fabbe57="">₹{{ Number(userdetails.total_win1).toFixed(2) }}</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                      <div v-else>
                        <svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity: 0.2; margin-top: 40px; justify-content: center"><use xlink:href="#icon-empty"></use></svg>
                        <p class="text-center">No Data</p>
                      </div>
                   </div>
                   <div v-if="currentTab === 'tab3'">
                    <div class="amount__box" v-if="userdetails.total_bet2!=null">
                         <h3>₹{{ Number(userdetails.total_bet2).toFixed(2) }}</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="amount__box" v-else>
                         <h3>₹0.00</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="result__box" v-if="userdetails.total_num2>0">
                         <div data-v-0fabbe57="" class="gamestats-container-list-wrapper">
                            <div data-v-0fabbe57="" class="gamestats-container-items">
                               <div data-v-0fabbe57="" class="gamestats-container-item">
                                  <h1 data-v-0fabbe57=""><img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/loterry-13b4d059.png" src="../assets/png/loterry-13b4d059.png"><span data-v-0fabbe57="">lottery</span></h1>
                                  <div data-v-0fabbe57="" class="gamestats-container-item-content">
                                     <img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/gameStatsSteps-fde7a66f.png" src="../assets/png/gameStatsSteps-fde7a66f.png">
                                     <div data-v-0fabbe57="" class="gamestats-container-item-content-list">
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Total bet</h4>
                                           <h5 data-v-0fabbe57="">₹{{ Number(userdetails.total_bet2).toFixed(2) }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Number of bets</h4>
                                           <h5 data-v-0fabbe57="">{{ userdetails.total_num2 }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Winning amount</h4>
                                           <span data-v-0fabbe57="">₹{{ Number(userdetails.total_win2).toFixed(2) }}</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                      <div v-else>
                        <svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity: 0.2; margin-top: 40px;"><use xlink:href="#icon-empty"></use></svg>
                        <p class="text-center">No Data</p>
                      </div>
                   </div>
                   <div v-if="currentTab === 'tab4'">
                    <div class="amount__box" v-if="userdetails.total_bet3!=null">
                         <h3>₹{{ Number(userdetails.total_bet3).toFixed(2) }}</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="amount__box" v-else>
                         <h3>₹0.00</h3>
                         <p>Total bet</p>
                      </div>
                      <div class="result__box" v-if="userdetails.total_num>0">
                         <div data-v-0fabbe57="" class="gamestats-container-list-wrapper">
                            <div data-v-0fabbe57="" class="gamestats-container-items">
                               <div data-v-0fabbe57="" class="gamestats-container-item">
                                  <h1 data-v-0fabbe57=""><img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/loterry-13b4d059.png" src="../assets/png/loterry-13b4d059.png"><span data-v-0fabbe57="">lottery</span></h1>
                                  <div data-v-0fabbe57="" class="gamestats-container-item-content">
                                     <img data-v-0fabbe57="" alt="" class="" data-origin="../assets/png/gameStatsSteps-fde7a66f.png" src="../assets/png/gameStatsSteps-fde7a66f.png">
                                     <div data-v-0fabbe57="" class="gamestats-container-item-content-list">
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Total bet</h4>
                                           <h5 data-v-0fabbe57="">₹{{ Number(userdetails.total_bet3).toFixed(2) }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Number of bets</h4>
                                           <h5 data-v-0fabbe57="">{{ userdetails.total_num3 }}</h5>
                                        </div>
                                        <div data-v-0fabbe57="">
                                           <h4 data-v-0fabbe57="">Winning amount</h4>
                                           <span data-v-0fabbe57="">₹{{ Number(userdetails.total_win3).toFixed(2) }}</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                      <div v-else>
                        <svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity: 0.2; margin-top: 40px;"><use xlink:href="#icon-empty"></use></svg>
                        <p class="text-center">No Data</p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'RechargeHistoryView',
         data(){
             return{
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                },
                rechargerecord: {
    
                },
                betbox: {
                   page: 1,
                   total:10,
                },
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          getUserdetails(){
             this.userdetails.username = localStorage.getItem('username');
             axios.get('https://manage.games350.com/trova/src/api/game_statics.php?action=today&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet = this.Users[0].total_bet;
                this.userdetails.total_num = this.Users[2].total_num;
                this.userdetails.total_win = this.Users[1].total_win;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manage.games350.com/trova/src/api/game_statics.php?action=yesterday&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet1 = this.Users[0].total_bet1;
                this.userdetails.total_num1 = this.Users[2].total_num1;
                this.userdetails.total_win1 = this.Users[1].total_win1;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manage.games350.com/trova/src/api/game_statics.php?action=week&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet2 = this.Users[0].total_bet2;
                this.userdetails.total_num2 = this.Users[2].total_num2;
                this.userdetails.total_win2 = this.Users[1].total_win2;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manage.games350.com/trova/src/api/game_statics.php?action=month&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet3 = this.Users[0].total_bet3;
                this.userdetails.total_num3 = this.Users[2].total_num3;
                this.userdetails.total_win3 = this.Users[1].total_win3;
             }).catch((err) => {
                 console.log(err);
             })
          },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>